<template>
   <div>
      <!-- Breadcrumb start -->
      <section class="bradcrumb_sec">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="bread_crumb_box mb_22">
                     <h2 class="heading_1 font_bold mb_23">Mindful Ergonomics</h2>
                     <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item"><router-link class="navbar-brand m-0 p-0" :to="{ name: 'user_home' }">
         Home
        </router-link></li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'ergo_essentials' }">Ergo Essentials</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'posture_perfect' }">Posture Perfect</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'sitting_pretty' }">Sitting Pretty</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'sit_stand_basics' }">Sit/Stand Basics</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'typing_dos_and_donts' }">Typing Do’s and Don’ts</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'mousing_dos_and_donts' }">Mousing Do’s and Don’ts</router-link>
                           </li>
                           <li class="breadcrumb-item active" aria-current="page">Mindful Ergonomics</li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Breadcrumb end -->
      <!-- pagination Starts -->
      <section class="pagination_sec">
         <div class="pagination-nav">
            <div class="container-fluid custome_container">
               <div class="row justify-content-center">
                  <div class="col-md-11">
                     <div class="row justify-content-between">
                        <div class="col-md-12">
                           <nav aria-label="Page navigation example">
                              <ul class="pagination justify-content-between mb_32">
                                 <li class="page-item">
                                    <router-link :to="{ name:'mousing_dos_and_donts' }"   class="page-link border-0 text_black" aria-label="Previous">
                                       <span aria-hidden="true">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="40" height="29.631" viewBox="0 0 43.575 29.631">
                                          <g id="Left_arrow" data-name="Left arrow" transform="translate(2.75 3.967)">
                                            <path id="Path_30" data-name="Path 30" d="M1755.8,353.578l-10.914,10.889,10.937,10.965" transform="translate(-1744.886 -353.656)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                            <path id="Path_31" data-name="Path 31" d="M1755.8,353.578l-10.914,10.889,10.937,10.965" transform="translate(-1728.886 -353.656)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                          </g>
                                       </svg>
                                          Previous
                                       </span>
                                    </router-link>
                                 </li>
                                 <li class="page-item">
                                    <router-link :to="{ name:'ergo_essentials' }" class="page-link border-0 text_black" aria-label="Next">
                                       <span aria-hidden="true" class="font_bold">
                                          <span class="text_primary">Up Next<span class="mobile_hide">: </span></span>
                                          <span class="mobile_hide">Ergo Essentials</span>
                                             <svg xmlns="http://www.w3.org/2000/svg" width="33.575" height="29.631" viewBox="0 0 33.575 29.631" style="margin-left:5px;">
                                             <g id="Right_arrow" data-name="Right arrow" transform="translate(-12884.354 20078.389)">
                                               <path id="Path_30" data-name="Path 30" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11159.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                               <path id="Path_31" data-name="Path 31" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11143.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                             </g>
                                          </svg>
                                       </span>
                                    </router-link>
                                 </li>
                              </ul>
                           </nav>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- pagination Ends -->
      <!-- image text start -->
      <section class="image_text_sec  bg_grey pt_50 pb_50">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="row">

                      <div class="col-md-6">
                        <div class="image_left_side">
                           <img src="../assets/images/mindfull_bannerr.png" alt="img">
                        </div>
                     </div>

                     <div class="col-md-6">
                        <div class="image_text_right_side mw-100">
                           <h2 class="mb_22">What exactly is mindful ergonomics?</h2>
                           <p class="text_black mb_30">
                          At first blush, mindful ergonomics is the practice of being aware of what your body is doing while working (or doing anything actually). Most, if not all of us, plug in and start tapping and clacking away at our laptops, computers, phones and tablets. 
                           </p>
                           <p>
                              So while you know what activity you are engaged in, how often have you asked yourself how your body is doing while engaged? This is the essence of mindful ergonomics.
                           </p>

                        </div>
                     </div>

                     
                    
                  </div>
               </div>
            </div>
         </div>
      <div class="text-center down_to_section newarrow">
            <a   v-scroll-to="{
     el: '#accordian_secc',
     duration: 500,
     easing: 'linear',
     offset: -350,
 }">
                              <svg xmlns="http://www.w3.org/2000/svg" width="50.132" height="35.219" viewBox="0 0 57.132 61.219">
  <g id="Down_arrow" data-name="Down arrow" transform="translate(-20020.894 -12891.354) rotate(90)">
    <path id="Path_30" data-name="Path 30" d="M1744.937,353.578l24.648,24.591-24.7,24.763" transform="translate(11180.239 -20427.715)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
    <path id="Path_31" data-name="Path 31" d="M1744.937,353.578l24.648,24.591-24.7,24.763" transform="translate(11150.357 -20427.715)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
  </g>
</svg>
            </a>
         </div>
      </section>
      <!-- Set Yourself up for success Ends -->
      <!-- Accordion  start -->
      <section id="accordian_secc" class="accordion_sec my_80">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-12 col-xl-8">
                  <div class="accordion" id="accordionExample">
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                              <img src="../assets/images/foundation_icon.png" alt="img"> What is the foundation of mindful ergonomics?
                           </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                           data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                             <div class="claim_text_box">
                                <p class="text_black mb_34"><strong>Mindful ergonomics starts with three healthy movement pillars.</strong></p>
                             <ul class="pl_18 mb_0 text_balticsea bullet_numeric">
                                <li><p class="mb_14">Respect the natural alignment of your body.  This means using your body in the manner in which it was designed.  Translation? Avoid rounding your shoulders or craning your neck forward.</p></li>
                                <li><p class="mb_14">Use your joints in a mid-range of motion. This includes keeping your neck from craning forward, bending your wrists back when typing and hunching over your laptop. </p></li>
                                <li><p class="mb_14">Make coordinated vs. isolated movements. This means recruiting bigger body parts to help smaller body parts.  So, instead of  twisting at your wrists to strike an enter or delete key, move your hand and arm together to get to those keys. </p></li>
                                </ul>
                                </div>
                              
                           </div>
                        </div>
                     </div>
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              <img src="../assets/images/happening_icon.png" alt="img">What’s happening in your body? 
                           </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                           data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                              <p>
                               <span class="font_bold ">Do a quick body scan from head to toe</span>  
                             and just notice how you are feeling. Are you holding tension anywhere?  How is your posture?  Are you upright and balanced?  Tune into what is happening.
                              </p>
                              <p>
                                 <span class="font_bold ">Do you feel tension anywhere?</span> 
                                 Discomforts? Are you reaching for things?  Observing the healthy movement pillars? 
                              </p>
                              <p>
                                 <span class="font_bold ">Take action. </span> 
                                 Awareness is the first step to take corrective action. If needed, reposition yourself or your setup to maintain a healthy and comfortable posture and use your body well. 
                              </p>
                             
                             
                           </div>
                        </div>
                     </div>

                  

                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Claim Your Space end -->
      <!-- more WFH tips start  -->
      <section class="more_lab_tips bg_grey pt_42">
         <div class="container">
            <div class="row justify-content-center ">
               <div class="col-md-12 col-xl-11 px_20">
                  <div class="row">
                     <div class="col-md-12">
                        <h4 class="heading_4 font_bold mb-0">More Office Tips</h4>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'posture_perfect' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/posture_perfect/posture_perfect1.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Posture Perfect</h6>
                                 <p>Enjoy having the best posture in the room. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'sitting_pretty' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/posture_perfect/posture_perfect2.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Sitting Pretty </h6>
                                 <p>Learn how to sit in total comfort day in and day out.</p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'sit_stand_basics' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/posture_perfect/posture_perfect3.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Sit/Stand Basics</h6>
                                 <p>Do you sit or stand when you work? How about a bit of both? </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'typing_dos_and_donts' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/posture_perfect/posture_perfect4.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Typing Do’s and Don’ts</h6>
                                 <p>These typing tips will protect your hands and speed you up. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'mousing_dos_and_donts' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/posture_perfect/posture_perfect5.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Mousing Do’s &amp; Don’ts</h6>
                                 <p>Healthy mousing is key to your well being and productivity. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'mindful_ergonomics' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/posture_perfect/posture_perfect6.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Mindful Ergonomics</h6>
                                 <p>Being mindful of how you do what you do is key to staying healthy. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- more WFH tips end  -->
   </div>
</template>

<script>
   export default {
      name: "sitting_pretty",
      mounted(){
         document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title; 
      },
   }
</script>